import React from "react";
import { Modal, Button, Space, Radio, Input, Form } from "antd";
import { LikeFilled, DislikeOutlined } from "@ant-design/icons";
import { patchFeedback } from "../../api/api.service";
import { PatchFeedbackInput } from "../../api/api.types";




export interface IProps {
    isVisible?: boolean;
    setIsModalVisible: Function;
    cdHash: string;
    num_atendimento: string;
}


const FeedbackCard = ({ isVisible, setIsModalVisible, cdHash, num_atendimento }: IProps) => {
    const [selected, setSelected] = React.useState(0);
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    const Suggestions: Array<string> = [ 
        'Não está relacionado com o que eu buscava',
	    'O conteúdo desta solução não é relevante',
	    'As informações estão incorretas',
	    'Outro....'
        ]
    
    const radioOnChange = ( e : any ) => {
        setSelected(e.target.value);
        setButtonDisabled(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const validateMessages = {               
        required: '${label} is required!',  // eslint-disable-line no-template-curly-in-string
    };

    const [form] = Form.useForm();
    
    const sendFeedback = async (selected : any) => {
        const sugestao = (selected.hasOwnProperty('suggestion')) ?  selected.suggestion :  Suggestions[selected.radio-1]
        const payload:PatchFeedbackInput = {
            cdhash: cdHash,
            sugestao: sugestao,
            num_atendimento: num_atendimento
          }
          const res = await patchFeedback(payload)
          if(res.error){
            console.error(res.error)
          }
    }

    const validateForms = async () => {
        try {
            const values = await form.validateFields()
            form.resetFields();
            await sendFeedback(values.user);
            setSelected(0)
            setIsModalVisible(false)
        } catch (error) {
            console.log('Validate Failed:', error)
        }
    }
    if (!isVisible) return null;
    return (
        <Modal 
            title="Comentários" 
            okText="Enviar"
            cancelText="Cancelar"
            visible={isVisible}
            onCancel={handleCancel}
            onOk={validateForms}
            okButtonProps={{ disabled:  buttonDisabled  }}
        
        >
            <Space direction="vertical">
                <p>
                    Precisa de ajuda? Confira nossa página de Perguntas 
                    Frequentes para obter dicas, truques e soluções 
                    para problemas comuns.
                </p>
                <p>
                    <Button 
                        type="link"><LikeFilled /> Gosto do MyAnalitics
                    </Button>
                </p>
                <p>
                    <Button 
                        type="link"><DislikeOutlined />Não gosto do MyAnalitics
                    </Button>
                </p>
                <p>
                    Deseja algo mais?
                </p>
                <Form form={form} name="nest-messages" validateMessages={validateMessages}>
                    <Radio.Group onChange={radioOnChange} value={selected} defaultValue={false}>
                        <Form.Item name={['user', 'radio']} >
                            <Space direction="vertical">
                                {
                                    Suggestions.map((comp, i) => {
                                        if ( i === 3 ) {
                                            return <Radio value={i + 1} key={i}>
                                                        {comp}
                                                        {selected === 4 ?
                                                            <Form.Item name={['user', 'suggestion']} label="Suggestion" rules={[{ required: true }]}> 
                                                                <Input.TextArea  style={{ width: 250, marginLeft: 10 }} /> 
                                                            </Form.Item>
                                                            : null}
                                                            
                                                    </Radio>
                                        } 
                                        return <Radio value={i+1} key={i}>{comp} </Radio>
                                    }
                                )}
                            </Space>
                        </Form.Item>
                    </Radio.Group>
                </Form>
                
            </Space>
    </Modal>
    );
};

export default FeedbackCard;
