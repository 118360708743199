import React from "react";
import ResultCard from "../Result-card/Result-card";
import styled from "styled-components";
import { Spin, Row, Col, Menu, Dropdown, Button, Affix } from "antd";
import { LoadingOutlined, DownOutlined} from "@ant-design/icons";
import logo_realizar_pesquisa from "../../resources/realize_uma_pesquisa.svg";
import logo_nenhum_resultado from "../../resources/nenhum_resultado.svg";
import {Chamado} from "../../api/api.types";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export interface IProps {
  cdHash: string;
  chamados: Chamado[];
  error: boolean;
  loading: boolean;
  searchText: string;
}
const Logo = styled.img`
  margin: 25% 35%;
  color: white;
`;

const Results = ({ error, cdHash, chamados, loading, searchText }: IProps) => {
  const [filter, setFilter] = React.useState(-1);

  React.useEffect(() => {
    setFilter(-1);
  }, [chamados]);

  const onClickFilter = (e: any) => {
    
    switch (e.key) {
      case '0':
        /* Descending */
        chamados = chamados.sort((a, b) => a.data > b.data ? -1 : 1)
        break;
      case '1':
        /* Ascending */
        chamados = chamados.sort((a, b) => a.data < b.data ? -1 : 1)
        break;
      case '2':
        /* Descending */
        chamados = chamados.sort((a, b) => a.score > b.score ? -1 : 1)
        break;
      default:
        break;
    }
    setFilter(e.key)
  };
  const menuItens: Array<string> = [ 
    'Filtro por mais recente',
    'Filtro por mais antigos',
    'Filtro por melhor pontuação',
  ]
  const menu = (
    <Menu onClick={onClickFilter}>
      {
        menuItens.map((comp, i) => {
          return <Menu.Item key={i}>{comp}</Menu.Item>
        }
      )}
    </Menu>
  );
  return (
    <div style={{ width:"100%" }}>
      <Row align="middle">
        <Col span={18} order={1}>
          <h2>Resultados</h2>
        </Col>
        <Col span={6} order={2}>
          <Affix offsetTop={10}>
            <Dropdown overlay={menu}>
              <Button block={true} size="large" style={{ fontSize: "18px" }} >
                {filter === -1 ? <span>Filtros   </span> : <span>{menuItens[filter]}</span>} <DownOutlined />
              </Button>
            </Dropdown>
          </Affix>
        </Col>
      </Row>
      {loading ? (
        <Spin indicator={antIcon} />
      ) : error ? (
        <div>Erro na consulta</div>
      ) : chamados.length > 0 ? (
        chamados.map((item, i) => (
          <ResultCard cdHash={cdHash} chamado={item} searchText={searchText} />
        ))
      ) : chamados.length === 0 && cdHash === '' ? (
        <div>{/* Você não tem nenhuma sugestão */}
          <Logo src={logo_realizar_pesquisa} alt="" />
        </div>
        
      ) : (
        <div>
          <Logo src={logo_nenhum_resultado} alt="" />
        </div>
        
      )}
    </div>
  );
};

export default Results;
