import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import Cobertura from "../../resources/cobertura.png";
import s4Logo from "../../resources/s4.svg";
import { loginRequest } from "../../auth/authConfig";

const Container = styled.div`
  display: flex;
  height: 86vh;
`;
const LoginDiv = styled.div`
  background-color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Logo = styled.img`
  margin: 0px 10px;
`;
interface SIProps {
  img: string;
}

const StyledImage = styled.div<SIProps>`
  background: ${(p) =>
    "transparent url(" + p.img + ") 100% 50% no-repeat padding-box"};
  flex: 2;
`;

const Text = styled.div`
  padding: 40px 0px;
  color: #000000;
  font-size: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  color: #000000;
  background-color: #c6e8f6;
`;


const Login = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Container>
      <StyledImage img={Cobertura} />
      <LoginDiv>
        <Logo src={s4Logo} alt="" />
        <Text>Sugestão de Solução de Salts na Sustentação</Text>
        <StyledButton
          type="primary"
          className="login-form-button"
          onClick={handleLogin}
        >
          Login com AD Softplan
        </StyledButton>
      </LoginDiv>
    </Container>
  );
};

export default Login;
