import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, Select, Row, Col, DatePicker } from "antd";
import styled from "styled-components";
import React, { useEffect, useState, useCallback } from "react";
import { getClients, postRecomend } from "../../api/api.service";
import { Client } from "../../api/api.types";
import HintCard from "../Hint-card/Hint-card";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const StyledSelect = styled(Select)`
  width: 70%;
  margin: 5px 0px;
`;

const StyledInput = styled(Input)`
  width: 70%;
  margin: 5px 0px;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const SearchButton = styled(Button)`
  float: right;
  margin: 6px 0px;
`;

type Base = {
  name: string;
};

export interface IProps {
  onResult: Function;
  isLoading: Function;
  vertical: string;
  hasToken: boolean;
}

const Search = ({ onResult, isLoading, vertical, hasToken }: IProps) => {
  const [disabled, setDisabled] = useState(true);
  const [clients, setClients] = useState([] as Client[]);
  const [client, setClient] = useState(0);
  const [bases] = useState([{ name: "SCCD" }, { name: "SAC" }] as Base[]);
  const [base, setBase] = useState("*");
  const [text, setText] = useState("");
  const [data, setDate] = useState([]);
  const [sistema, setSystem] = useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const dateFormat = 'DD/MM/YYYY';

  const disabledDate = (current:any) => {
    return current && current > moment().endOf('day')
  }

  const showModal = () => {
    setIsModalVisible(!isModalVisible)
  };

  const fetchClients = useCallback(async () => {
    if (hasToken) {
      setClient(0);
      const res = await getClients(vertical);
      if (res.data) {
        if (vertical === 'MP' && base.length > 1){
          setClients(res.data.clients.filter(client => client.base === base));
        } else{
          setClients(res.data.clients)
        }
      } else {
        console.error(res.error);
      }
    }
  }, [vertical, hasToken, base]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const onSearch = async () => {
    isLoading(true);
    const res = await postRecomend({
      chamado: text,
      cod_cliente: Number(client),
      data_source: base.toLowerCase(),
      vertical: vertical.toLowerCase(),
      sistema: sistema,
      data: data
    });
    isLoading(false);
    if (res.data) {
      console.log(res.data);
      onResult(true, res.data.chamados, res.data.cdhash, text);
    } else {
      onResult(false, [], "", "");
      console.error(res.error);
    }
  };

  const textChange = (e: any) => {
    const val = e.target.value;
    setText(val);
    setDisabled(val.length === 0);
  };

  const clientChange = (e: any) => {
    setClient(e);
  };

  const baseChange = (e: any) => {
    setBase(e);
  };

  const dateChange = (e: any) => {
    e = e ?? []
    e.map((item: any) => 
      moment(item).format(dateFormat)
    )
    setDate(e);
  };
  
  const systemChange = (e: any) => {
    setSystem(e.target.value);
  };

  return (
    <SearchWrapper>
      <h2>Pesquisar chamados semelhantes</h2>

      <p>Filtrar por base</p>
      <StyledSelect
        allowClear
        showSearch
        placeholder="Selecione a opção"
        onChange={baseChange}
        optionFilterProp="children"
        defaultValue={"*"}
        value={base}
      >
        <Option key={0} value={"*"}>
          {"SAC e SCCD"}
        </Option>
        {bases.map((base) => (
          <Option key={base.name} value={base.name}>
            {base.name}
          </Option>
        ))}
      </StyledSelect>
      <br />
      <br />
      <p>Filtrar por cliente</p>
      <StyledSelect
        allowClear
        showSearch
        placeholder="Selecione a opção"
        onChange={clientChange}
        optionFilterProp="children"
        defaultValue={0}
        value={client}
      >
        <Option key={0} value={0}>
          {`Todos os clientes ${vertical}`}
        </Option>
        {clients.map((client) => (
          <Option key={client.code} value={client.code}>
            {client.name}
          </Option>
        ))}
      </StyledSelect>
      <br />
      <br />
        <p>Filtrar por sistema</p>
          <StyledInput onChange={systemChange}/>
        <br />
      <br />
        <p>Filtrar por data</p>
          <DatePicker.RangePicker 
            allowClear={true}
            defaultValue={[null, moment(moment())]}
            format={dateFormat}
            disabledDate={disabledDate}
            showNow={true}
            onChange={dateChange} 
          />
      <br />
      <br />
      <TextArea
        className={"search-input"}
        placeholder="Digite sua pesquisa"
        onChange={textChange}
        rows={4}
        allowClear
      />
      <Row align="top">
        <Col flex="auto">
          <Button onClick={showModal}
                  style={{ fontSize: "18px" }} 
                  size="large" 
                  type="link" 
                  icon={<InfoCircleOutlined />}>DICAS</Button>
        </Col>
        <Col flex="auto">
          <SearchButton
            disabled={disabled}
            type="primary"
            className={"search-button"}
            onClick={onSearch}
            icon={<SearchOutlined />}
            >
            Pesquisar
          </SearchButton>
        </Col>
      </Row>
      <HintCard   isVisible={ isModalVisible } 
                      setIsModalVisible={ setIsModalVisible } 
      />
    </SearchWrapper>
  );
};

export default Search;
