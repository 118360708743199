import React from "react";
import { Modal, List, Space, Typography, Collapse } from "antd";

export interface IProps {
    isVisible?: boolean;
    setIsModalVisible: Function;
}
const { Text } = Typography;
const { Panel } = Collapse;


const ResultContent = ({ isVisible, setIsModalVisible }: IProps) => {
    const handleOK = () => {
        setIsModalVisible(false);
    };

    const data = [
        {
            operator : '+',
            description: 'Significa o operador AND',
            example: 'jack + jill -> retorna eventos que documentos jack e jill '
        },
        {
            operator : '|',
            description: 'Significa o operador OR',
            example: 'tom | jerry -> retorna documentos com tom ou jerry'
        },
        {
            operator : '-',
            description: 'Nega um token único',
            example: 'foo bar -baz -> retorna documento com foo ou bar, mas que não contém bar'
        },
        {
            operator : '"',
            description: 'Junta uma série de tokens para procurar uma frase',
            example: '"this is a test" -> retorna documentos que contém a frase: this is a test'
        },
        {
            operator : '*',
            description: 'No fim de um termo significa o prefixo da query',
            example: 'tom* -> retorna documentos com o radical tom: tomas, tomilho, tomar'
        },
        {
            operator : '( e )',
            description: 'Significa precedência',
            example: '(Tom | jerry) + peter -> retorna os documentos, resolvendo primeiro o termo entre parenteses, como: tom e peter, jerry e peter'
        },
        {
            operator : '~N',
            description: 'Depois de uma palavra significa editar distância (fuzziness)',
            example: 'john~2 - Esse termo combina com as seguintes palavras: “jean,” “johns,” “jhon,” and “horn”'
        },
        {
            operator : '~N',
            description: 'Depois de uma frase significa quantidade de resíduos',
            example: '"Uma garrafa de leite"~1 -> retorna documentos como: Uma garrafa de leite, Uma garrafa pequena de leite'
        }
    ];

    return (
        <div>
        {isVisible ? 
                <Modal 
                    title="Dicas" 
                    okText="OK"
                    cancelButtonProps={{ hidden: true }}
                    visible={isVisible}
                    onOk={handleOK}
                    onCancel={handleOK}
                >
                    <Space direction="vertical">
                        <p>
                        Dicas para melhor aproveitar a ferramenta, operadores suportados:
                        </p>
                        <List
                        size='large'
                        bordered
                        dataSource={data}
                        renderItem={item => (
                                <Collapse defaultActiveKey={['0']}>
                                <Panel 
                                    showArrow={true}
                                    extra={<Text mark>{item.operator}</Text>}
                                    header={item.description}
                                    key={data.findIndex(i => i.description === item.description)}>
                                        <p>
                                            Exemplo: {item.example}
                                        </p>
                                </Panel>
                                </Collapse>
                        )}
                        />
                        <p>
                        Caso queira adicionar algum dos termos reservados a pesquisa, é necessário adicionar o operador (\), Ex: "Guarda\-chuva".
                        </p>
                    </Space>
            </Modal>
            : null 
        }
        </div>
    );
};

export default ResultContent;
