import { DefaultTheme } from 'styled-components'

export const LightTheme: DefaultTheme = {
    headerbg: "#5C5CFF",
    textcolor: "#4D4D4D",
    layoutbg: "#EAEAEA",
    contentbg: "#F9F9F9",
    itembg: "#FFFFFF",
    primarycolor: "#0078D7"
};

export const DarkTheme: DefaultTheme = {
    headerbg: "#5C5CFF",
    textcolor: "#FFFFFF",
    layoutbg: "#121212",
    contentbg: "#1C1C1C",
    itembg: "#363636",
    primarycolor: "#0078D7"
};