import { useMsal } from "@azure/msal-react";
import { Button, Dropdown, Menu, Switch, Tooltip } from "antd";
import logo_alterar_vertical from "../../resources/alterar_vertical.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

const LogoVertical = styled.img`
  margin: 0 0 5px 10px;
`;

export interface MenuProps {
  vertical: string;
  setVertical: Function;
  theme: string;
  setTheme: Function;
}

export const MenuContent = ({
  vertical,
  setVertical,
  theme,
  setTheme,
}: MenuProps) => {
  const { instance } = useMsal();

  const logOut = () => {
    instance.logoutRedirect({
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        return false;
      },
    });
  };

  const verticalChange = (e: any) => {
    setVertical(e.key);
  };

  const changeTheme = (change: boolean) => {
    const theme = change ? "dark" : "light";
    setTheme(theme);
  };

  const menu = (
    <Menu onClick={verticalChange}>
      <Menu.Item key="PJ">PJ</Menu.Item>
      <Menu.Item key="TJ">TJ</Menu.Item>
      <Menu.Item key="MP">MP</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <span>{theme === "light" ? "Ativar" : "Desativar"} tema escuro</span>
      {/* TODO Ajustar tamanhos da barra de botões (cosméticos) */}
      <Switch
        checked={theme === "dark"}
        onChange={(check) => changeTheme(check)}
      />
      <Dropdown overlay={menu} trigger={["click"]}>
        <span>
          <LogoVertical src={logo_alterar_vertical} alt="" />
          <span style={{ margin: "15px 0px 0 0 " }}>
            {vertical}
          </span>
        </span>
      </Dropdown>
      <Tooltip title="Sair">
        <Button
          style={{ margin: "0 0 0 20px" }}
          type="text"
          onClick={(event) => logOut()}
          icon={<FontAwesomeIcon icon={faSignOutAlt} color="white" />}
        />
      </Tooltip>
    </div>
  );
};
