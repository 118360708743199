import React from "react";
import {Descriptions} from "antd";
import styled from "styled-components";
import {Chamado} from "../../api/api.types";
import moment from 'moment';

export interface IProps {
    chamado: Chamado;
    isExpanded?: boolean;
}

const Highlight = styled.span`
  background-color: rgba(255, 185, 0, 0.5);
`;

const ResultContent = ({chamado, isExpanded}: IProps) => {
    const sliceContent = (str: string) => `${str.slice(0, 200)}...`;
    
    const useHighlights = (text: string) => {
        const split_high = text.split(/<b>(.*?)<\/b>/)
        const res = split_high.map((high, i) => {
            if (i % 2 !== 0) {
                return <Highlight>{high}</Highlight>;
            }
            return <span>{high}</span>;
        });
        return res;
    }

    const dateFormat = 'DD/MM/YYYY';

    const formatData = (data : any) => {
        return moment(data).format(dateFormat)
    }
    
    const atendimento = useHighlights(chamado.atendimento);
    const solucao = useHighlights(chamado.solucao);
    const short = useHighlights(
        sliceContent(chamado.atendimento)
    );

    return (
        <Descriptions column={3}>
            <Descriptions.Item label="Cliente">{chamado.cliente ?? ""}</Descriptions.Item>
            <Descriptions.Item label="Sistema">{chamado.sistema ?? ""}</Descriptions.Item>
            <Descriptions.Item label="Data">{formatData(chamado.data) ?? ""}</Descriptions.Item>
            <Descriptions.Item span={3} label="Atendimento">
                {isExpanded ? (
                    <div>{atendimento.map((fragment) => fragment)}</div>
                ) : (
                    <div>{short.map((fragment) => fragment)}</div>
                )}
            </Descriptions.Item>
            {isExpanded && (
                <Descriptions.Item span={2} label="Solução">
                    <div>{solucao.map((fragment) => fragment)}</div>
                </Descriptions.Item>
            )}
        </Descriptions>
    );
};

export default ResultContent;
