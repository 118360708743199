// import { AccountInfo } from "@azure/msal-common";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Col, Divider, Layout, Row, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import logo_ds from "../../resources/datascience.svg";
import s4Logo from "../../resources/s4_white.svg";
import logo_sp from "../../resources/softplan_rgb.png";
import Login from "../Login/Login";
import Results from "../Results/Results";
import Search from "../Search/Search";
import { DarkTheme, LightTheme } from "./Themes";
import useLocalStorage from "../../hooks/localStorage";
import { MenuContent } from "../Menu/Menu";
import { API } from "../../api/api.service";
import { AccountInfo } from "@azure/msal-browser";

const { Header, Content, Footer } = Layout;

const { Text } = Typography;

const StyledText = styled(Text)`
  margin-top: 15px;
  color: white;
  font-size: 8px;
`;

const StyledLayout = styled(Layout)`
  background-color: ${(p) => p.theme.layoutbg};
  min-height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  h2, body, .ant-card-head-title {
    color: ${(p) => p.theme.textcolor};
  }

  .ant-input, .ant-select, .ant-select-selector,.ant-empty-description, .ant-card, .ant-select-item-empty{
    color: ${(p) => p.theme.textcolor};
    background-color: ${(p) => p.theme.itembg} !important
  }

  .ant-switch-checked{
    background-color: ${(p) => "#003e6e"}
  }

  .ant-switch{
    margin: 10px;
  }


  .ant-descriptions-item-content, .ant-descriptions-item-label{
    color: ${(p) => p.theme.textcolor};
    white-space: pre-wrap;
  }

  .ant-descriptions-item-label{
    font-weight: bold;
  }
`;

const StyledHeader = styled(Header)`
  background-color: ${(p) => p.theme.headerbg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  padding: 0px 14px;
`;

const StyledContent = styled(Content)`
  background-color: ${(p) => p.theme.contentbg};
  margin: 1em 5%;
  padding: 20px;
  min-height: 820px;
`;

const StyledFooter = styled(Footer)`
  display: flex;
  background-color: #000000;
  color: white;
  justify-content: center;
  font-size: 35px;
`;

const StyledCol = styled(Col)`
  display: flex;
  padding: 0px 20px;
`;

const Logos = styled.img`
  margin: 0px 10px;
`;

const Logo = styled.img`
  margin: 0px 10px;
  color: white;
`;

const LogoSP = styled.img`
  margin: 0px 10px;
  color: white;
  width: 151px;
  height: 30px;
`;

const App = () => {
  const [result, setResult] = useState([] as any[]);
  const [cdHash, setCdHash] = useState("");
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [vertical, setVertical] = useLocalStorage("vertical", "PJ");
  const { instance, accounts, inProgress } = useMsal();
  const [hasToken, setHasToken] = useState(false)

  const fetchToken = useCallback(async () => {
    if (inProgress === "none" && accounts.length > 0) {
      // Retrieve an access token
      const response = await instance.acquireTokenSilent({
        account: accounts[0] as AccountInfo,
        scopes: ["User.Read"],
      });
      if (response.accessToken) {
        API.defaults.headers = { token: response.accessToken };
        setHasToken(true)
      }
    }
  }, [inProgress, accounts, instance]);

  useEffect(() => {
    fetchToken()
  }, [fetchToken]);

  const onResult = (success: boolean, res: any[], cdHash: string, searchText: string) => {
    setError(!success);
    setResult(res);
    setCdHash(cdHash);
    setSearchText(searchText);
  };

  const isLoading = (status: boolean) => setLoading(status);

  return (
    <ThemeProvider theme={theme === "light" ? LightTheme : DarkTheme}>
      <>
        <GlobalStyle />
        <StyledLayout>
          <StyledHeader>
            <div>
              <Logo src={s4Logo} alt="" />
              <b>Sugestão de Solução de Salts na Sustentação</b>
            </div>
            <AuthenticatedTemplate>
              <MenuContent
                vertical={vertical}
                setVertical={setVertical}
                theme={theme}
                setTheme={setTheme}
              />
            </AuthenticatedTemplate>
          </StyledHeader>
          <AuthenticatedTemplate>
            <StyledContent>
              <Row>
                <StyledCol span={8}>
                  <Search
                    onResult={onResult}
                    isLoading={isLoading}
                    vertical={vertical}
                    hasToken={hasToken}
                  ></Search>
                </StyledCol>
                <StyledCol span={16}>
                  <Results
                    cdHash={cdHash}
                    error={error}
                    loading={loading}
                    chamados={result}
                    searchText={searchText}
                  ></Results>
                </StyledCol>
              </Row>
            </StyledContent>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>

          <StyledFooter>
            <Logos src={logo_ds} alt="" />
            <Divider type="vertical" style={{ height: "35px" }} />
            <StyledText>Powered by</StyledText>
            <LogoSP src={logo_sp} alt="" />
          </StyledFooter>
        </StyledLayout>
      </>
    </ThemeProvider>
  );
};

export default App;
