import axios from 'axios';
import {ClientsOutput, FeedbackInput, FeedbackOutput, RecommendInput, RecommendOutput, 
        PatchFeedbackInput, PatchFeedbackOutput} from "./api.types";

const API_ENDPOINT = process.env.REACT_APP_API_URL ?? "https://localhost:8000/";

export const API = axios.create({
    baseURL: API_ENDPOINT
});


export const postRecomend = async (payload: RecommendInput) => {
    try {
        const response = await API.post<RecommendOutput>("recommendations", payload)
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        return {
            data: null,
            error: err
        }
    }
}

export const getClients = async (vertical: string) => {
    try {
        const response = await API.get<ClientsOutput>("clients?vertical="+vertical)
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        return {
            data: null,
            error: err
        }
    }
}

export const postFeedback = async (payload: FeedbackInput) => {
    try {
        const response = await API.post<FeedbackOutput>("feedbacks", payload)
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        return {
            data: null,
            error: err
        }
    }
}

export const patchFeedback = async (payload: PatchFeedbackInput) => {
    try {
        const response = await API.patch<PatchFeedbackOutput>("feedbacks", payload)
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        return {
            data: null,
            error: err
        }
    }
}



