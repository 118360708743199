import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { LikeOutlined, DislikeOutlined, SmileOutlined } from "@ant-design/icons";
import { postFeedback } from "../../api/api.service";
import { Chamado, FeedbackInput } from "../../api/api.types";
import FeedbackCard from "../Feedback-card/Feedback-card";

export enum Selects {
  None,
  Wrong,
  Right,
}

const RightButton = styled(Button)`
 background-color: #01ae76;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export interface IProps {
  item: Chamado;
  cdHash: string;
  searchText: string;
}

const Feedback = ({ item, cdHash, searchText }: IProps) => {
  const [selected, setSelected] = React.useState(Selects.None);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(!isModalVisible)
  };
  
  const onClick = async (selection: Selects) => {
    setSelected(selection);
    const payload:FeedbackInput = {
      cdhash: cdHash,
      num_atendimento: item.num_atendimento,
      item: item.item,
      origem_atendimento: item.origem_atendimento,
      value: selection === Selects.Right,
      cliente: item.cliente,
      solucao: item.solucao,
      search_text: searchText
    }
    const res = await postFeedback(payload)
    if(res.error){
      console.error(res.error)
    }
  };

  return (
    <Wrapper>
      <div>{selected ? null : <div>Esta sugestão foi útil?</div>}</div>
      {selected === Selects.Wrong ? null : (
        <RightButton
          className={"right-button"}
          type="primary"
          onClick={() => onClick(Selects.Right)}
          shape="circle"
          icon={<LikeOutlined />}
          size="small"
        />
      )}
      {selected === Selects.Right ? null : (
        <Button
          type="primary"
          onClick={() => onClick(Selects.Wrong)}
          danger
          shape="circle"
          icon={<DislikeOutlined />}
          size="small"
        />
      )}
      {selected ? <div>
                    <Button 
                      onClick={showModal}
                      type="link">Obrigado! Deseja acrescentar algo mais? <SmileOutlined />
                    </Button>
                  </div> : null}
      <FeedbackCard   isVisible={ isModalVisible } 
                      setIsModalVisible={ setIsModalVisible } 
                      cdHash={ cdHash }
                      num_atendimento={ item.num_atendimento }
      />

    </Wrapper>
  );
};

export default Feedback;
