import React from "react";
import { Card, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Feedback from "../Feedback/Feedback";
import ResultContent from "../Result-content/Result-content";
import styled from "styled-components";
import {Chamado} from "../../api/api.types";

export interface IProps {
  chamado: Chamado;
  cdHash: string;
  searchText: string;
}

const StyledCard = styled(Card)`
  margin: 20px 0px;
  .ant-card-head-title{
    color: #5C5CFF;
  }
  svg{
    color: ${(p) => p.theme.textcolor};
  }
  .ant-card-extra{
      /* order:-1; */
      margin-right: 0.1em;
  }
`

const ResultCard = ({ chamado, cdHash, searchText }: IProps) => {
  const [expanded, setSize] = React.useState(false);

  const sizeResult = () => {
    setSize(!expanded);
  };

  return (
    <StyledCard
      className="result-card"
      title={`Atendimento ${chamado.num_atendimento}/${chamado.item} - ${chamado.origem_atendimento}`}
      extra={
        <Button
          type="link"
          onClick={sizeResult}
          icon={expanded ? <UpOutlined /> : <DownOutlined />}
        />
      }
      bordered={false}
    >
      <ResultContent chamado={chamado} isExpanded={expanded} />
      {expanded && <Feedback cdHash={cdHash} item={chamado} searchText={searchText} />}
    </StyledCard>
  );
};

export default ResultCard;
